@import '../scss/_bootstrap-variables';

body .p-picklist .p-picklist-list .p-picklist-item {
  padding: 0 0.857rem;
}

body {
  background-color: #ffffff;
}

.card {
  background-color: inherit;
}

.modal-content {
  background-color: #ffffff;
}

.form-control {
  background-color: #ffffff;
}

.form-select {
  background-color: #ffffff;
}


.p-g-1,
.p-g-2,
.p-g-3,
.p-g-4,
.p-g-5,
.p-g-6,
.p-g-7,
.p-g-8,
.p-g-9,
.p-g-10,
.p-g-11,
.p-g-12 {
  padding: 0;
  float: none;
}

.dns_umd_head {
  font-weight: 100;
  padding: 0.5rem 0rem;
}

.p-paginator .p-dropdown {
  float: left;
}

.p-paginator .p-paginator-current {
  float: right;
}

.p-table .p-paginator-bottom {
  padding: 0.5rem;
}

.p-paginator {
  padding: 0.3rem 1rem;
}

/* Jonas */

.item__btn {
  float: left;
  margin-left: 5px;
  margin-right: 5px;
}

.item__delete--btn {
  color: #ff5049;
  font-size: 1.36rem;
  margin-bottom: 3px;
}

.item__edit--btn {
  color: #035718;
  font-size: 1.34rem;
  margin-bottom: 1px;
}

.item__view--btn {
  color: #460ae9;
  font-size: 1.46rem;
  margin-bottom: 4px;
}

.item__email--btn {
  color: #460ae9;
  font-size: 1.18rem;
  margin-bottom: 4px;
}

.item__clone--btn {
  color: #d350bd;
  font-size: 1.22rem;
  margin-bottom: 3px;
}

.item__print--btn {
  color: #000;
  font-size: 1.28rem;
  margin-bottom: 2px;
}

.item__plus--btn {
  color: #000;
  font-size: 1.35rem;
  margin-bottom: 0px;
}

.item__modification--btn {
  color: #460ae9;
  font-size: 1.2rem;
  margin-bottom: 3px;
}

.item__storno--btn {
  color: #ff5049;
  font-size: 1.46rem;
  margin-bottom: 2px;
}

.item__glpost--btn {
  color: #035718;
  font-size: 1.29rem;
  margin-bottom: 2px;
}

.item__glrevoke--btn {
  color: #ff5049;
  font-size: 1.35rem;
  margin-bottom: 1px;
}

.item__share--btn {
  color: #035718;
  font-size: 1.31rem;
  margin-bottom: 1px;
}

.item__newspaper--btn {
  color: #000;
  font-size: 1.15rem;
  margin-bottom: 1px;
}

.item__file--btn {
  color: #000;
  font-size: 1.1rem;
  margin-bottom: 4px;
}

.item__delete--btn,
.item__edit--btn,
.item__view--btn,
.item__print--btn,
.item__plus--btn,
.item__clone--btn,
.item__modification--btn,
.item__storno--btn,
.item__email--btn,
.item__glpost--btn,
.item__glrevoke--btn,
.item__share--btn,
.item__newspaper--btn,
.item__file--btn {
  padding: 0px 1px;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  display: none;
}

.item__delete--btn:focus,
.item__edit--btn:focus,
.item__view--btn:focus,
.item__print--btn:focus,
.item__plus--btn:focus,
.item__clone--btn:focus,
.item__modification--btn:focus,
.item__storno--btn:focus,
.item__email--btn:focus,
.item__glpost--btn:focus,
.item__glrevoke--btn:focus,
.item__share--btn:focus,
.item__newspaper--btn:focus,
.item__file--btn:focus {
  outline: none;
}

.item__delete--btn:active,
.item__edit--btn:active,
.item__view--btn:active,
.item__print--btn:active,
.item__plus--btn:active,
.item__clone--btn:active,
.item__modification--btn:active,
.item__storno--btn:active,
.item__email--btn:active,
.item__glpost--btn:active,
.item__glrevoke--btn:active,
.item__share--btn:active,
.item__newspaper--btn:active,
.item__file--btn:active {
  transform: translateY(-2px);
}

.button_column {
  transition: transform 1s !important;
}

.button_column:hover {
  transform: translateX(10px);
}

.button_column:hover .item__view--btn {
  display: inline-block;
}

.button_column:hover .item__edit--btn {
  display: inline-block;
}

.button_column:hover .item__delete--btn {
  display: inline-block;
}

.button_column:hover .item__clone--btn {
  display: inline-block;
}

.button_column:hover .item__print--btn {
  display: inline-block;
}

.button_column:hover .item__plus--btn {
  display: inline-block;
}

.button_column:hover .item__modification--btn {
  display: inline-block;
}

.button_column:hover .item__storno--btn {
  display: inline-block;
}

.button_column:hover .item__email--btn {
  display: inline-block;
}

.button_column:hover .item__glpost--btn {
  display: inline-block;
}

.button_column:hover .item__glrevoke--btn {
  display: inline-block;
}

.button_column:hover .item__share--btn {
  display: inline-block;
}

.button_column:hover .item__newspaper--btn {
  display: inline-block;
}

.button_column:hover .item__file--btn {
  display: inline-block;
}

.disable-scroll .p-dialog-content {
  overflow-x: hidden !important;
}

.p-picklist-transfer-buttons {
  height: 350px;
}

html {
  font-size: 14px;
}

/* @media screen and (min-width: 1800px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 2200px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 2600px) {
  html {
    font-size: 17px;
  }
}

@media screen and (min-width: 3000px) {
  html {
    font-size: 18px;
  }
} */

// .form-date {
//   width: 25%;
// }

// .form-datetime {
//   width: 30%;
// }

.form-label {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.p-tabview .p-tabview-panels {
  padding-bottom: 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #2196f3 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  text-decoration: none !important;
  color: #6c757d;
}

.detail-dialog-content {
  // width: 40vw;
  min-width: 1000px;
  max-width: 80vw;
}

.update-dialog-content {
  // width: 40vw;
  min-width: 1000px;
  max-width: 80vw;
}

.details-detail-dialog-content {
  // width: 38vw;
  min-width: 950px;
  max-width: 75vw;
}

.details-update-dialog-content {
  // width: 38vw;
  min-width: 950px;
  max-width: 75vw;
}

.login-dialog-content {
  min-width: 600px;
  max-width:40vw;
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid #c8c8c8;
  background: #f4f4f4;
}

.dialog-header {
  border-bottom: 1px solid #c8c8c8;
  background: #f4f4f4;
}

.p-dialog .p-dialog-content {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.p-dialog .p-dialog-footer {
  border-top: 1px solid #c8c8c8;
  background: #f4f4f4;
  text-align: left;
  padding: 1rem;
}

dl:last-child {
  margin-bottom: 0;
}

p-editor {
  width:100%;
}

.p-editor-container .p-editor-content .ql-editor:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border: 1px solid #2196f3;
}

p-editor.ng-dirty.ng-invalid {
  border: none;
}

p-editor.ng-pristine.ng-invalid {
  border: none;
}

p-editor.ng-invalid .p-editor-container .p-editor-content .ql-editor {
  // border: 1px solid #f44336;
  border-left: 5px solid red;
}

p-editor.ng-valid.required-editor .p-editor-container .p-editor-content .ql-editor {
  // border: 1px solid #f44336;
  border-left: 5px solid green;
}

input[type='checkbox'] {
  height: 33.5px;
  width: 33.5px;
  margin: auto;
  margin-bottom: 0;
}

.common-voc-categ-dialog-mask {
  padding-top: 15vh;
}

.currency-dialog-mask {
  padding-top: 15vh;
}

.partner-dialog-mask {
  padding-top: 10vh;
}

.gl-category-dialog-mask {
  padding-top: 15vh;
}

.gl-booking-dialog-mask {
  padding-top: 12vh;
}

.alert-detail-already-exist {
  margin-bottom: 1rem;
  color: red;
}

.alert-valid-dates-error {
  margin-top: 1rem;
  color: red;
}

.align-right {
  text-align: right;
}

.p-menubar .p-submenu-list {
  width: max-content;
}

.p-datatable .p-table-small .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-datatable .p-table-small .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

// /* _alert.scss:33 */
// .close {
//   position: absolute;
//   top: 0;
//   right: 0;
//   padding: $alert-padding-y $alert-padding-x;
//   color: inherit;
// }

// /* _modal.scss:108 */
// .close {
//   padding: $modal-header-padding;
//   // auto on the left force icon to the right even when there is no .modal-title
//   margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
// }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  position: relative;
}

p-dropdown.ng-invalid > .p-dropdown > .p-inputtext,
p-dropdown.ng-dirty.ng-invalid > .p-dropdown > .p-inputtext {
  border: 1px solid #ced4da;
  border-left: 5px solid red;
}

p-dropdown.ng-valid.required-dropdown > .p-dropdown > .p-inputtext,
p-dropdown.ng-dirty.ng-valid.required-dropdown > .p-dropdown > .p-inputtext {
  // border: 1px solid #ced4da;
  border-left: 5px solid green;
}

p-calendar.ng-invalid > .p-calendar > .p-inputtext,
p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border: 1px solid #ced4da;
  border-left: 5px solid red;
}

// p-calendar.ng-invalid > .p-calendar > .p-inputtext:focus,
// p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext:focus{
//   box-shadow: 0 0 0 0.2rem #d5e8f7;
//   border-left: 5px solid red;
// }

p-calendar.ng-valid.required-calendar > .p-calendar > .p-inputtext,
p-calendar.ng-dirty.ng-valid.required-calendar > .p-calendar > .p-inputtext {
  // border: 1px solid #ced4da;
  border-left: 5px solid green;
}

p-inputnumber.ng-invalid > .p-inputnumber > .p-inputtext,
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border: 1px solid #ced4da;
  border-left: 5px solid red;
}

// p-inputnumber.ng-invalid > .p-inputnumber > .p-inputtext:focus,
// p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext:focus{
//   box-shadow: 0 0 0 0.2rem #d5e8f7;
//   border-left: 5px solid red;
// }

p-inputnumber.ng-valid.required-inputnumber > .p-inputnumber > .p-inputtext,
p-inputnumber.ng-dirty.ng-valid.required-inputnumber > .p-inputnumber > .p-inputtext {
  // border: 1px solid #ced4da;
  border-left: 5px solid green;
}

.p-inputnumber-input{
  text-align: right;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width:100%;
}

.p-inputnumber, p-inputNumber {
  width:100%;
}

.p-calendar {
  height:33.5px;
  width:100%;
}

.p-dropdown {
  height:33.5px;
  width:100%;
}

.p-multiselect {
  // height:33.5px;
  width:100%;
}

.form-select.ng-invalid,
.form-control.ng-invalid {
  border-left: 5px solid red;
}

// .form-select.ng-invalid,
// .form-control.ng-invalid,
// .ng-invalid .value-accessor-form-control {
//   border-left: 5px solid red;
// }

.invalid-lov-input-element {
  border-left: 5px solid red !important;
}

.ng-valid .valid-required-lov-input-element {
  border-left: 5px solid green;
}

// .ng-valid .required-lov-input-element {
//   border-left: 5px solid green;
// }

.p-component:disabled {
  color: #212529;
  opacity: 1;
  background-color: #E9ECEF;
}

.p-menuitem > .p-disabled {
  background-color: transparent;
}

p-dropdown > .p-disabled {
  background-color: var(--bs-secondary-bg);
}

.p-menuitem > .p-disabled > .p-menuitem-text {
  color: #B2BABB;
}

.p-menuitem-link {
  text-decoration: none !important;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
  color: #495057 !important;
  text-decoration: none !important;
}

.p-inputtext, .p-inputnumber
.p-editor-container .p-editor-content .ql-editor {
  color: #212529; // = bootstrap form-control class color
  border-radius: 0.15rem !important;
}

// bootstrap form-control
.p-inputtext:enabled:hover {
  border-color: #ced4da;
}

// bootstrap form-control
.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: $primary;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select {
  border: none;
  background: transparent;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: none;
  color: #2196f3;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active > .pi-filter:before {
  color: #2196f3;
}

.p-dialog-title {
  width: 100%;
}

.p-text-after-input {
  margin-top: 35px;
  padding-left: 0;
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.p-radiobutton-label {
  padding-right:10px;
}

.p-radiobutton-group {
  padding-top: 10px;
}

.p-inline {
  display: inline-block;
}

.p-inline-end {
  display: inline-block;
  text-align: end;
}

.bold-and-big {
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.75rem;
}

.p-datatable .p-datatable-tbody > tr {
  padding: 0.8rem 1rem;
  text-align: left;
  border: solid #e9ecef;
  border-width: 0 0 1px;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.8rem 1rem;
  border: solid #e9ecef;
  border-width: 0 0 1px;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.8rem 1rem;
}

.details-list {
  border: 1px solid #ced4da;
  border-radius: 0.3rem !important;
}

.p-accordion .no-padding .p-accordion-content {
  padding: 0px !important;
}

.pi-search {
  cursor: pointer;
}

.checkbox-inline-label {
  vertical-align: middle;
  padding-right: 5px;
}

.checkbox-inline {
  display: inline-block;
  vertical-align: middle;
}

.p-sidebar .p-sidebar-header {
  padding: unset;
}

.alert-info {
  color: #125386;
  background-color: #a0d2fa;
  border-color: #a0d2fa;
}

.LEJART {
  background-color:rgb(248, 230, 233) !important;
  border: solid rgb(247, 130, 149) !important;
  border-width: 0 0 1px !important;
}

.RESZBEN_RENDEZETT {
  background-color: rgb(250, 227, 179) !important;
  border: solid rgb(238, 193, 96) !important;
  border-width: 0 0 1px !important;
}

.RENDEZETT {
  background-color: rgb(216, 247, 216) !important;
  border: solid rgb(132, 219, 132) !important;
  border-width: 0 0 1px !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #E3F2FD !important;
  color: #495057;
}

.text-blue {
  color: #460ae9 !important;
}

.upper-case {
  text-transform:uppercase;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  color: #495057;
  font-weight: 600;
  text-decoration: none !important;
}

.body-with-menu {
  width: 86%;
  display: inline-flex;
  float: inline-end;
}

.body-without-menu {
  width: 100%;
  display: inline-flex;
  float: inline-end;
}

.active-menu-item {
  color:#2196f3 !important;
}

.mt-checkbox {
  margin-top: 2rem;
}

.accordion-head-warning {
  border-color: rgb(234, 154, 6);
  background-color: #f2d0a8;
}

.accordion-head-new {
  border-color: rgb(68, 68, 241);
  background-color: #aad9fa;
}

.loading-button-container {
  position: relative;
  display: inline-block;
}

.loading-button-container button {
  position: relative;
  z-index: 1; /* Keeps the button above the animation */
}

.loading-spinner {
  position: absolute;
  top: 4px; /* Adjust to move dots inside the border */
  left: 4px; /* Adjust to move dots inside the border */
  width: calc(100% - 8px); /* Shrink to fit inside the button */
  height: calc(100% - 8px); /* Shrink to fit inside the button */
  pointer-events: none; /* Prevent interference with button clicks */
}

.loading-spinner .dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #516983; /* Blue dot color */
  border-radius: 50%;
  animation: moveDots 3s linear infinite, fadeDots 3s linear infinite;
}

.loading-spinner .dot:nth-child(1) { animation-delay: 0s, 0s; }
.loading-spinner .dot:nth-child(2) { animation-delay: 0.375s, 0.375s; }
.loading-spinner .dot:nth-child(3) { animation-delay: 0.75s, 0.75s; }
.loading-spinner .dot:nth-child(4) { animation-delay: 1.125s, 1.125s; }
.loading-spinner .dot:nth-child(5) { animation-delay: 1.5s, 1.5s; }
.loading-spinner .dot:nth-child(6) { animation-delay: 1.875s, 1.875s; }
.loading-spinner .dot:nth-child(7) { animation-delay: 2.25s, 2.25s; }
.loading-spinner .dot:nth-child(8) { animation-delay: 2.625s, 2.625s; }

@keyframes moveDots {
  0%   { top: 0; left: 50%; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Top-middle */
  12.5% { top: 0; left: 100%; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Top-right */
  25%  { top: 50%; left: 100%; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Right-middle */
  37.5% { top: 100%; left: 100%; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Bottom-right */
  50%  { top: 100%; left: 50%; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Bottom-middle */
  62.5% { top: 100%; left: 0; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Bottom-left */
  75%  { top: 50%; left: 0; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Left-middle */
  87.5% { top: 0; left: 0; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Top-left */
  100% { top: 0; left: 50%; transform: translate(-50%, -50%); opacity: 1; visibility: visible; } /* Back to start */
}

@keyframes fadeDots {
  0% { opacity: 1; visibility: visible; }
  20% { opacity: 1; visibility: visible; }
  40% { opacity: 0.6; visibility: visible; }
  60% { opacity: 0.3; visibility: visible; }
  80% { opacity: 0; visibility: hidden; }
  100% { opacity: 0; visibility: hidden; }
}






